import { UnsafeAction as Action } from '../../interfaces';
import { Update } from '@ngrx/entity';
import { KioskModel } from './kiosk.model';

const ACTION_PREFIX = '[Kiosks]';

export const KioskActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class KioskAction {
  static load(): Action {
    return {
      type: KioskActionType.LOAD,
    };
  }

  static loadSuccess(customFields): Action {
    return {
      type: KioskActionType.LOAD_SUCCESS,
      payload: customFields,
    };
  }

  static loadFailed(err): Action {
    return {
      type: KioskActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(): Action {
    return {
      type: KioskActionType.ADD,
    };
  }

  static addSuccess(data): Action {
    return {
      type: KioskActionType.ADD_SUCCESS,
      payload: data,
    };
  }

  static addFailed(err): Action {
    return {
      type: KioskActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(): Action {
    return {
      type: KioskActionType.UPDATE,
    };
  }

  static updateSuccess(data): Action {
    return {
      type: KioskActionType.UPDATE_SUCCESS,
      payload: data,
    };
  }

  static updateFailed(err): Action {
    return {
      type: KioskActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static delete(data: Update<KioskModel>): Action {
    return {
      type: KioskActionType.DELETE,
      payload: data,
    };
  }

  static deleteSuccess(id): Action {
    return {
      type: KioskActionType.DELETE_SUCCESS,
      payload: id,
    };
  }

  static deleteFailed(data: Update<KioskModel>, err): Action {
    return {
      type: KioskActionType.DELETE_FAILED,
      payload: { data, err },
    };
  }
}
