import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction } from '../../interfaces';
import { map } from 'rxjs/operators';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { KioskModel } from './kiosk.model';

@Injectable()
export class KioskApi {
  private endpoint = 'timetracking/kiosks/';

  constructor(private gateway: ApiGateway) {}

  public getKiosks(dispatchStart?: UnsafeAction) {
    return this.gateway.get(this.endpoint, null, dispatchStart).pipe(map((res) => reformatListResponse('Kiosk', res)));
  }

  public save(data: KioskModel, dispatchStart?: UnsafeAction) {
    const postData = { Kiosk: data };

    return this.gateway
      .post(this.endpoint, postData, null, dispatchStart)
      .pipe(map((res) => reformatEntityResponse('Kiosk', res)));
  }

  public update(id, data: KioskModel, dispatchStart?: UnsafeAction) {
    const putData = { Kiosk: data };

    return this.gateway
      .put(this.endpoint + id, putData, null, dispatchStart)
      .pipe(map((res) => reformatEntityResponse('Kiosk', res)));
  }

  public delete(id, dispatchStart?: UnsafeAction) {
    return this.gateway.delete(this.endpoint + id, null, dispatchStart);
  }

  public inviteUsers(id, dispatchStart?: UnsafeAction) {
    return this.gateway.post(this.endpoint + id + '/invite', null, dispatchStart);
  }

  public loadPincode(id: string) {
    return this.gateway.get('pin/' + id);
  }
}
