import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { KioskModel } from './kiosk.model';

export interface KioskState extends EntityState<KioskModel> {
  isLoading?: boolean;
  error?: any;
}

export const kioskAdapter: EntityAdapter<KioskModel> = createEntityAdapter<KioskModel>({});

export const initialState: KioskState = kioskAdapter.getInitialState({
  isLoading: false,
  error: null,
});
