import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import omit from 'lodash-es/omit';
import { KioskApi } from './kiosk.api';
import { KioskAction } from './kiosk.action';
import { KioskModel } from './kiosk.model';

@Injectable()
export class KioskService {
  constructor(private api: KioskApi, private store: Store<AppState>) {}

  public load() {
    return this.api.getKiosks(KioskAction.load()).pipe(
      tap((data) => {
        this.store.dispatch(KioskAction.loadSuccess(data));
      }),
      catchError((response) => {
        this.store.dispatch(KioskAction.loadFailed(response));
        return throwError(response);
      })
    );
  }

  public save(data) {
    return this.api.save(data, KioskAction.add()).pipe(
      tap((response) => {
        this.store.dispatch(KioskAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(KioskAction.addFailed(response));
        return throwError(response);
      })
    );
  }

  public update(id: number, data) {
    return this.api.update(id, data, KioskAction.update()).pipe(
      tap((response) => {
        this.store.dispatch(KioskAction.updateSuccess({ id, changes: omit(response, ['id']) }));
      }),
      catchError((response) => {
        this.store.dispatch(KioskAction.updateFailed(response));
        return throwError(response);
      })
    );
  }

  public delete(id: string) {
    return this.api.delete(id, KioskAction.delete({ id, changes: { loading: true } })).pipe(
      tap((response) => {
        this.store.dispatch(KioskAction.deleteSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(KioskAction.deleteFailed({ id, changes: { loading: false } }, response));
        return throwError(response);
      })
    );
  }

  public inviteUsers(id: string) {
    return this.api.inviteUsers(id);
  }

  loadPincode(id: string): Observable<{ Pin: string }> {
    return this.api.loadPincode(id);
  }
}
